/* eslint-disable react/prop-types */
import React from 'react';
import {
  Input,
  OutlineButton,
  Typography,
  LinkButton,
  PrimaryButton,
} from '@d1.cx/components';
import { TrashAlt } from '@d1.cx/icons';
import {
  Center,
  Container,
  Wrapper,
  WrapperButtons,
  WrapperDate,
  WrapperInputs,
  Line,
  Spacing,
} from './styles';
import useSelectedInput from './useSelectedInput';
import SuccessContent from '../SuccessContent';

function SelectedInputsExport({ data, product, toggle }) {
  const {
    handleAddNewInput,
    fields,
    handleRemoveInput,
    handleChangeInput,
    handleSubmit,
    loading,
    open,
    errorAPI,
    endDate,
    startDate,
    maxRange,
    handleChangeDate,
  } = useSelectedInput();

  return (
    <Container>
      {open ? (
        <Center>
          <SuccessContent
            error={errorAPI}
            messageOne={errorAPI ? 'Ops! Alguma coisa deu errado! Por favor, verifique sua conexão.' : 'A exportação será enviada para o seu email dentro de alguns minutos!'}
            messageTwo={errorAPI ? 'Tente novamente!' : 'Muito obrigado!'}
          />
          <LinkButton onClick={toggle}>Fechar</LinkButton>
        </Center>
      ) : (
        <>
          <WrapperDate>
            <Typography>
              Selecione a data inicial e data final para gerar o arquivo CSV.
            </Typography>
            <Typography bold>
              A data é limitada em 4 meses.
            </Typography>
            <div>
              <div>
                <Typography vertical="10px" horizontal="5px" bold>
                  Data inicial:
                </Typography>
                <input
                  type="date"
                  value={startDate}
                  onChange={(event) => handleChangeDate('START', event)}
                />
              </div>
              <Spacing />
              <div>
                <Typography vertical="10px" horizontal="5px" bold>
                  Data final:
                </Typography>
                <input
                  type="date"
                  min={startDate}
                  max={maxRange}
                  value={endDate}
                  onChange={(event) => handleChangeDate('END', event)}
                />
              </div>
            </div>
          </WrapperDate>
          <Line />
          <Wrapper>
            <Typography vertical="10px">
              Digite os campos adicionais que deseja que sejam enviados por email:
            </Typography>
            <OutlineButton onClick={handleAddNewInput}>Adicionar</OutlineButton>
          </Wrapper>
          <WrapperInputs>
            {fields?.map((_, index) => (
              <Wrapper>
                <Input
                  placeholder="Nome do campo"
                  onChange={(e) => handleChangeInput(e, index)}
                />
                <LinkButton onClick={() => handleRemoveInput(index)}>
                  {' '}
                  <TrashAlt width="30px" color="red" />
                </LinkButton>
              </Wrapper>
            ))}
          </WrapperInputs>
          <WrapperButtons>
            <OutlineButton onClick={toggle} loading={loading}>
              Cancelar
            </OutlineButton>
            <PrimaryButton
              onClick={() => {
                handleSubmit(
                  {
                    ...data,
                  },
                  product,
                );
              }}
              loading={loading}
              disabled={loading || fields.length < 1
                 || startDate?.length === 0 || endDate?.length === 0}
            >
              Enviar
            </PrimaryButton>
          </WrapperButtons>
        </>
      )}
    </Container>
  );
}

export default SelectedInputsExport;
