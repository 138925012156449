import { dispatch } from '../Config/store';
import { signIn, userInfo } from '../Config/Api/User';

const onSignIn = async (email, password) => {
  try {
    const response = await signIn({ email, password });
    const { token: USER_TOKEN } = response.token;
    await dispatch.Auth.saveAuthToken(USER_TOKEN);
    localStorage.setItem('@Visao360:JWT', USER_TOKEN);
    if (response) {
      await dispatch.User.resetCurrentProductAsync();
      await dispatch.User.resetUserAsync();
      await dispatch.User.saveUserAsync(email);
      return true;
    }

    return false;
  } catch (err) {
    return false;
  }
};

const verifyUser = async (history, errorRoute) => {
  try {
    await userInfo();

    return true;
  } catch (err) {
    dispatch.Auth.logOut();
    if (errorRoute !== window.location.pathname) {
      history.push(errorRoute);
    }
    return false;
  }
};

export { onSignIn, verifyUser };
