/* eslint-disable object-curly-newline */
/* eslint-disable comma-dangle */
/* eslint-disable consistent-return */
/* eslint-disable no-undef */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */

import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { endOfDay, format, startOfDay, addDays } from 'date-fns';
import { dispatch } from '../../Config/store';

const DAYS_TO_FILTER = 122;

const useSelectedInput = () => {
  const currentProductById = useSelector(
    (state) => state.User.currentProductById
  );
  const sendDocument = useSelector((state) => state.Document.downloadDocs);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorAPI, setErrorAPI] = useState(false);

  const [fields, setFields] = useState(['']);

  const [startDate, setStartDate] = useState(
    format(startOfDay(new Date()), 'yyyy-MM-DD')
  );
  const [endDate, setEndDate] = useState(
    format(endOfDay(addDays(new Date(), DAYS_TO_FILTER)), 'yyyy-MM-DD')
  );

  const [maxRange, setMaxRange] = useState(
    format(startOfDay(new Date()), 'yyyy-MM-DD')
  );

  const handleChangeDate = (type, event) => {
    type === 'START'
      ? setStartDate(event.target.value)
      : setEndDate(event.target.value);
    if (type === 'START') {
      if (event?.target.value.length > 0) {
        const addMoreDays = format(
          endOfDay(addDays(new Date(event.target.value), DAYS_TO_FILTER)),
          'yyyy-MM-dd'
        );
        setStartDate(event.target.value);
        setMaxRange(addMoreDays);
        setEndDate(addMoreDays);
      } else {
        setStartDate(event.target.value);
        setEndDate(event.target.value);
      }
    } else {
      setEndDate(event.target.value);
    }
  };

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [open]);

  const handleAddNewInput = useCallback(() => {
    setFields([...fields, '']);
  }, [fields]);

  const handleRemoveInput = useCallback(
    (index) => {
      const item = [...fields];
      item.splice(index, 1);
      setFields(item);
    },
    [fields]
  );
  const handleChangeInput = useCallback(
    ({ target }, index) => {
      const item = [...fields];
      item[index] = target.value;
      setFields(item);
    },
    [fields]
  );

  const handleSubmit = useCallback(
    async (filters) => {
      setLoading(true);
      setOpen(true);
      const count = Object.entries(filters).length;
      let data = {};
      if (count !== 0) {
        delete filters.limit;
        delete filters.page;
        filters.products = [currentProductById];
        delete filters.product;
        delete filters.categories;
        data = filters;
      } else {
        data.products = [currentProductById];
      }

      const filterFields = fields.filter((elem) => elem.length);
      data.filled_column_as_column = filterFields;
      data.log_action_blacklist = ['STEP_UPDATED'];

      const initialDate = moment(startDate).format('DD/MM/YYYY');
      const finalDate = moment(endDate).format('DD/MM/YYYY');
      data.initialDate = initialDate;
      data.finalDate = finalDate;

      try {
        await dispatch.Document.downloadDocumentsAsync(data);
      } catch (error) {
        setErrorAPI(true);
      }
      setFields([]);
      setLoading(false);
    },
    [fields, currentProductById, loading, sendDocument, startDate, endDate]
  );

  return {
    handleAddNewInput,
    handleRemoveInput,
    handleChangeInput,
    handleSubmit,
    fields,
    loading,
    handleClose,
    open,
    errorAPI,
    handleChangeDate,
    startDate,
    endDate,
    maxRange,
  };
};

export default useSelectedInput;
