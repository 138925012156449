/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const TabsStyled = styled.ul`
  width: 100%;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  height: 45px;
  border-bottom: 1px solid #e4e6f1;
  padding: 0 15px;
  position: relative;
  list-style-type: none;
`;
