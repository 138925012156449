/* eslint-disable import/no-cycle */
import axios from 'axios';

const API_URL = process.env.REACT_APP_BASE_URL;

const http = axios.create({
  baseURL: API_URL,
  validateStatus(status) {
    return status <= 500;
  },
});

const httpAuth = axios.create({
  baseURL: API_URL,
});

httpAuth.interceptors.request.use((config) => {
  const token = localStorage.getItem('@Visao360:JWT');
  if (token) {
    const newConfig = { ...config };
    newConfig.headers.Authorization = `Bearer ${token}`;
    return newConfig;
  }

  return config;
});

export { http, httpAuth };
