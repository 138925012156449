import React from 'react';
import { Link } from 'react-router-dom';

const About = () => (
  <div>
    <h1>About</h1>
    <Link to="/">back to home</Link>
  </div>
);

export default About;
